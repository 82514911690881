import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {OAuthService, UserInfo} from 'angular-oauth2-oidc-codeflow';
import { AuthorizationService } from '../shared/authorization/authorization.service';
const baseUrl = environment.BaseUrl;

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  headers: any;
  private authorizationService: AuthorizationService;
  constructor(protected httpClient: HttpClient) {
      this.headers = new HttpHeaders({
        'Content-Type' : 'application/json',
         Authorization : 'Bearer ' + sessionStorage.getItem('access_token'),
         id_token : sessionStorage.getItem('id_token')
      });
  }

  getDocFormTypeList() {
    return this.httpClient.get(baseUrl + environment.docTypeList + sessionStorage.getItem('interface_code'), {headers: this.headers});
   }

   getPicUrlList() {
    return this.httpClient.get(baseUrl + environment.picUrlList + sessionStorage.getItem('interface_code'), {headers: this.headers});
   }

   updatePicUrlList(reqJson: any) {
      return this.httpClient.post(baseUrl + environment.postPicUrlForm, JSON.stringify(reqJson), {headers: this.headers});
   }

   getdemandMasterList() {
    return this.httpClient.get(baseUrl + environment.getDemandList, {headers: this.headers});
   }

   updatedemandMasterList(reqJson: any) {
    return this.httpClient.post(baseUrl + environment.saveDemandList, JSON.stringify(reqJson), {headers: this.headers});
   }

   getDocumentCodeList() {
     return this.httpClient.get(baseUrl + environment.docCodeList, {headers: this.headers});
   }

   getDocumentCodeListById(formatId: string) {
    return this.httpClient.get(baseUrl + environment.getDocFormatDetailsById + formatId, {headers: this.headers});
   }

   getFormCodeList() {
    return this.httpClient.get(baseUrl + environment.formCodeList, {headers: this.headers});
  }

  deleteDocFormListById(id: any) {
    return this.httpClient.delete(baseUrl + environment.deleteDocFormList + id, {headers: this.headers});
  }

  addDocFormList(reqJson: any) {
    return this.httpClient.post(baseUrl + environment.postDocFormatForm, JSON.stringify(reqJson), {headers: this.headers});
  }

  getClientId() {
    return this.httpClient.get(baseUrl + environment.getClientId);
  }

  addDocumentCodeMaster(reqJson) {
    return this.httpClient.post(baseUrl + environment.saveDocumentMaster, JSON.stringify(reqJson), {headers: this.headers});
  }

  addFormatMaster(reqJson) {
    return this.httpClient.post(baseUrl + environment.saveFormatMaster, JSON.stringify(reqJson), {headers: this.headers});
  }

  getAllDocumentTypes() {
    return this.httpClient.get(baseUrl + environment.getAllDocumentTypes, {headers: this.headers});
  }

  getAllFormatTypes() {
    return this.httpClient.get(baseUrl + environment.getAllFormatTypes, {headers: this.headers});
  }

  getTranslationParameterList() {
    return this.httpClient.get(baseUrl + environment.transParamList, {headers: this.headers});
   }

  getDataQualitycheck(id){
    return this.httpClient.get(baseUrl + environment.dataQualitycheck + `?id=${id}`, {headers: this.headers})
  }

  createSegmemtTranslationData(reqJson) {
    return this.httpClient.post(baseUrl + environment.saveSegTransData, JSON.stringify(reqJson), {headers: this.headers});
  }

  deleteTranslationParameterById(id: any) {
    return this.httpClient.delete(baseUrl + environment.deleteTransParamList + id, {headers: this.headers});
  }
  getSegmentCodeList() {
    return this.httpClient.get(baseUrl + environment.getSegementList, {headers: this.headers});
  }

  getLanguageList() {
    return this.httpClient.get(baseUrl + environment.getLanguageList, {headers: this.headers});
  }

  getRecipientsListFromService() {
    return this.httpClient.get(baseUrl + environment.getRecipientList, {headers: this.headers});
  }

  deleteRecpListById(id: any) {
    return this.httpClient.delete(baseUrl + environment.deleteRecipientList + id, {headers: this.headers});
  }

  addRecipientEmail(reqJson) {
    return this.httpClient.post(baseUrl + environment.saveRecipientList, JSON.stringify(reqJson), {headers: this.headers});
  }

  savePitToPentRequest(inputJson) {
    return this.httpClient.post(baseUrl + environment.savePitToPentRequest, JSON.stringify(inputJson), {headers: this.headers});
  }

  deleteDemandRequestByIds(inputJson) {
    return this.httpClient.post(baseUrl + environment.cancelPitToPentRequest, JSON.stringify(inputJson), {headers: this.headers});
  }

  getTraslationStatusbyId(transLabel) {
    return this.httpClient.get(baseUrl + environment.getSegTranslationStatus + transLabel, {headers: this.headers});
  }

  getExportData() {
    return this.httpClient.get(baseUrl + environment.exportDemandRequest , {headers: this.headers});
  }
}
