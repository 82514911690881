import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {OpenIdCallBackComponent} from './authorization/callback/open-id-call-back.component';
import {TemplateModule} from './template/template.module';
import { EnvironmentModule } from './environment/environment.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TemplateModule,
        EnvironmentModule,
        TranslateModule
    ],
    declarations: [
        OpenIdCallBackComponent
    ],
    exports: [
        RouterModule,
        OpenIdCallBackComponent,
        TemplateModule,
        TranslateModule
    ],
    providers: [
    ]

})
export class SharedModule { }
