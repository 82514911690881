import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddDocFormsComponent } from '../add-doc-forms/add-doc-forms.component';
import { CommonService } from 'src/app/services/common.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/shared/common/common';
import { DataTableDirective } from 'angular-datatables';
import { element } from '@angular/core/src/render3';
import { AddTranslationParameterComponent } from 'src/app/model/add-translation-parameter/add-translation-parameter.component';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { TranslationStatusComponent } from 'src/app/model/translation-status/translation-status.component';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-translation-parameter-list',
  templateUrl: './translation-parameter-list.component.html',
  styleUrls: ['./translation-parameter-list.component.css']
})
export class TranslationParameterListComponent extends Common implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  transParamList: any;
  selectedRow: any;
  dtTrigger: Subject<any> = new Subject();
  fromMinDate = new Date();
  toMinDate = new Date();
  startDateString: any;
  // tslint:disable-next-line:max-line-length
  constructor(private confirmDialogService: ConfirmDialogService, protected httpclient: HttpClient, protected toastr: ToastrService, protected modalService: NgbModal) {
    super(toastr, httpclient);
  }

  ngOnInit() {
    this.selectedRow = [];
    this.startDateString = new Date();
    this.getTranslationParamterList();
  }

  getTranslationParamterList(): void {

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getTranslationParameterList().subscribe(resp => {
      this.selectedRow = [];
      const tempArray = [];
      const tArray = [];
      Object.values(resp).forEach((elements) => {
        tempArray[elements.segLabel] = [];
        // tslint:disable-next-line: no-string-literal
        tempArray[elements.segLabel]['langCode'] = [];
        // tslint:disable-next-line: no-string-literal
        tempArray[elements.segLabel]['lastTransReqDate'] = '';
      });
      Object.values(resp).forEach((elements) => {
        // tslint:disable-next-line: no-string-literal
        tempArray[elements.segLabel]['langCode'].push(elements.langCode);
        // tslint:disable-next-line: no-string-literal
        tempArray[elements.segLabel]['lastTransReqDate'] = elements.lastTransReqDate;
      });
      Object.entries(tempArray).forEach((keys, values) => {
        const temp = [];
        // tslint:disable-next-line: no-string-literal
        temp['transLabel'] = keys[0];
        // tslint:disable-next-line: no-string-literal
        temp['langLabel'] = keys[1].langCode.join(', ');
        // tslint:disable-next-line: no-string-literal
        temp['lastTransReqDate'] = new DatePipe('en-US').transform(keys[1].lastTransReqDate, 'dd-MM-yyyy');
        tArray.push(temp);
      });
      this.transParamList = tArray;
      this.dtTrigger.next();
    },
      error => {
        this.selectedRow = [];
        this.transParamList = [];
        this.dtTrigger.next();
        this.ShowNotification('Error while loading list', 'E');
      });
  }

  selectedItem(selectedItem: any, status) {
    if (status === true) {
      this.selectedRow.push(selectedItem);
    } else {
      const tempArray = [];
      this.selectedRow.forEach(itm => {
        if (itm.transLabel !== selectedItem.transLabel) {
          tempArray.push(itm);
        }
      });
      this.selectedRow = tempArray;
    }
    console.log(this.selectedRow);
  }

  createDemand() {
    if (this.selectedRow.length > 0 && this.startDateString !== undefined && this.startDateString !== '') {
      const inputJson = [];
      this.selectedRow.forEach(seg => {
        inputJson.push({ requestDate: new DatePipe('en-US').transform(this.startDateString, 'dd-MM-yy'), segLabel: seg.transLabel });
      });
      this.savePitToPentRequest(inputJson).subscribe(res => {
        this.ShowNotification('PIT to PENT Request Placed Successfully', 'S');
        // this.startDateString = '';
        this.rerender();
        this.getTranslationParamterList();
      });
    } else if (this.startDateString === '' || this.startDateString === undefined) {
      this.ShowNotification('Please select request Date', 'E');
    } else if (this.selectedRow.length === 0) {
      this.ShowNotification('Please select any one', 'E');
    }
  }

  public open() {
    const title = 'Add Translation Parameter List';
    this.selectedRow = [];
    this.confirmDialogService.openModal(AddTranslationParameterComponent, title, 0)
      .then((confirmed) => {
        this.rerender(); this.getTranslationParamterList();
      })
      .catch(() => { this.rerender(); this.getTranslationParamterList(); });
  }

  editItem() {
    if (this.selectedRow.length === 1) {
      const title = 'Edit Translation Parameter List';
      this.confirmDialogService.openModal(AddTranslationParameterComponent, title, this.selectedRow[0])
        .then((confirmed) => {
          this.rerender();
          this.getTranslationParamterList();
        })
        .catch(() => { this.rerender(); this.getTranslationParamterList(); });
    } else {
      this.ShowNotification('Please select one item', 'E');
    }
  }

  deleteItem() {
    if (this.selectedRow.length === 1) {
      let respStr: any;
      this.confirmDialogService.confirm('Confirm', 'Do you really want to delete?')
        .then((confirmed) => {
          if (confirmed === true) {
            this.deleteTranslationParameterById(this.selectedRow[0].transLabel).subscribe(resp => {
              respStr = resp;
              if (respStr === true) {
                this.ShowNotification('Selected Row Deleted Successfully', 'S');
                this.rerender();
                this.getTranslationParamterList();
              } else {
                this.ShowNotification(resp.toString(), 'E');
              }
            });
          }
        })
        .catch(() => console.log('User dismissed'));
    } else {
      this.ShowNotification('Please select one item', 'E');
    }
  }

  viewStatus(label) {
    const title = 'Translation Status';
    this.confirmDialogService.openModal(TranslationStatusComponent, title, label)
      .then((confirmed) => {
      })
      .catch(() => { });
  }

  deleteDemandRequest() {
    if (this.selectedRow.length > 0) {
      let respStr: any;
      this.confirmDialogService.confirm('Confirm', 'Do you really want to delete demand request?')
        .then((confirmed) => {
          if (confirmed === true) {
            const items = [];
            this.selectedRow.forEach(elements => {
              items.push({ segLabel: elements.transLabel });
            });
            this.deleteDemandRequestByIds(items).subscribe(resp => {
              respStr = resp;
              if (respStr === 'SUCCESS') {
                this.ShowNotification('Demand Request Deleted Successfully', 'S');
              } else {
                this.ShowNotification(resp.toString(), 'E');
              }
            });
          }
        })
        .catch(() => console.log('User dismissed'));
    } else {
      this.ShowNotification('Please select atleast one item', 'E');
    }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  exportDemandRequest() {
    this.getExportData().subscribe((result: any[]) => {
      if(result.length > 0) {
        const header = ['segment', 'reference', 'pa_name', 'pa_name_modification_date', 'pa_description', 'pa_description_modification_date', 'pa_technical_description', 'pa_technical_description_modification_date'];
        let csv = result.map(row => header.map(fieldName => JSON.stringify(row[fieldName], [])).join(','));
        csv.unshift(header.join(',').toLocaleUpperCase());
        let csvArray = csv.join('\r\n');
        const blob = new Blob([csvArray], { type: 'text/csv' })
        saveAs(blob, "export_pent.csv");
      } else {
        this.toastr.info("No demand details found!");
      }
    });
  }
}
