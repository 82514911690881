import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/shared/common/common';

@Component({
  selector: 'app-add-format-code',
  templateUrl: './add-format-code.component.html',
  styleUrls: ['./add-format-code.component.css']
})
export class AddFormatCodeComponent extends Common implements OnInit {

  formCodeForms: FormGroup;
   // tslint:disable-next-line:max-line-length
   constructor(private formBuilder: FormBuilder, protected httpClient: HttpClient, protected activeModal: NgbActiveModal, protected toastr: ToastrService) {
     super(toastr, httpClient);
   }

  ngOnInit() {
    this.formCodeForms  =  this.formBuilder.group({
      formatCode: ['', Validators.required],
      formatLabel: ['', Validators.required]
    });
  }

  addFormatCode() {
    if (this.formCodeForms.status === 'VALID') {
      this.addFormatMaster(this.formCodeForms.value).subscribe(res => {
        this.ShowNotification('Formal Code Inserted Successfully', 'S');
        this.activeModal.dismiss();
      }, (err) => {
        this.ShowNotification(err, 'E');
      });

    } else {
        this.ShowNotification('Please fill required fields', 'E');
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }


}
