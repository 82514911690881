import {NgModule} from '@angular/core';
import {SharedModule} from '../shared.module';

import {RnltErrorsComponent} from './rnlt-errors.component';
import {RnltErrorService} from './rnlt.error.service';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        RnltErrorsComponent
    ],
    providers: [RnltErrorService]
})
export class RnltErrorsModule { }
