import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/shared/common/common';

@Component({
  selector: 'app-add-document-code',
  templateUrl: './add-document-code.component.html',
  styleUrls: ['./add-document-code.component.css']
})
export class AddDocumentCodeComponent extends Common implements OnInit{

   docForms: FormGroup;
   // tslint:disable-next-line:max-line-length
   constructor(private formBuilder: FormBuilder, protected httpClient: HttpClient, protected activeModal: NgbActiveModal, protected toastr: ToastrService) {
     super(toastr, httpClient);
   }

  ngOnInit() {
    this.docForms  =  this.formBuilder.group({
      docCode: ['', Validators.required],
      docLabel: ['', Validators.required],
      pimLabel: ['', Validators.required]
    });
  }

  addDocCode() {
    if (this.docForms.status === 'VALID') {
      this.addDocumentCodeMaster(this.docForms.value).subscribe(res => {
        this.ShowNotification('Document Code Inserted Successfully', 'S');
        this.activeModal.dismiss();
      }, (err) => {
        this.ShowNotification(err, 'E');
      });

    } else {
        this.ShowNotification('Please fill required fields', 'E');
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
