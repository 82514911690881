import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/shared/common/common';

@Component({
  selector: 'app-translation-status',
  templateUrl: './translation-status.component.html',
  styleUrls: ['./translation-status.component.css']
})
export class TranslationStatusComponent extends Common implements OnInit {

  @Input() title: string;
  @Input() SelectedItem: any;
  statusList: any;
  constructor(protected httpClient: HttpClient, protected activeModal: NgbActiveModal, protected toastr: ToastrService) {
    super(toastr, httpClient);
   }

  ngOnInit() {
    this.getTranslationStatus();
  }

  getTranslationStatus() {
    this.statusList = [];
    this.getTraslationStatusbyId(this.SelectedItem).subscribe(res => {
      this.statusList = res;
    }, err => {
      this.ShowNotification(err, 'E');
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
