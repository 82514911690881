import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {OAuthModule, ValidationHandler, JwksValidationHandler, OAuthModuleConfig} from 'angular-oauth2-oidc-codeflow';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {RnltErrorsModule} from './shared/error/rnlt-errors.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import {AuthorizationModule} from './shared/authorization/authorization.module';
import { oAuthModuleConfig } from './shared/authorization/config/oauth-module.config';
import { Bootstrap } from 'bootstrap';
import { DocFormsTypesComponent } from './components/doc-forms-types/doc-forms-types.component';
import { PicsUrlComponent } from './components/pics-url/pics-url.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddDocFormsComponent } from './components/add-doc-forms/add-doc-forms.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { LogoutComponent } from './components/logout/logout.component';
import { DemandMasterComponent } from './components/master/demand-master/demand-master.component';
import { DocumentCodeMasterComponent } from './components/master/document-code-master/document-code-master.component';
import { FormatCodeMasterComponent } from './components/master/format-code-master/format-code-master.component';
import { AddDocumentCodeComponent } from './model/add-document-code/add-document-code.component';
import { AddFormatCodeComponent } from './model/add-format-code/add-format-code.component';
import { TranslationParameterListComponent } from './components/translation-parameter-list/translation-parameter-list.component';
import { RecipientsListComponent } from './components/recipients-list/recipients-list.component';
import { AddTranslationParameterComponent } from './model/add-translation-parameter/add-translation-parameter.component';
import { AddRecipientsComponent } from './model/add-recipients/add-recipients.component';
import { DatepickerModule, BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TranslationStatusComponent } from './model/translation-status/translation-status.component';
import { DataQualityListComponent } from './components/data-quality-list/data-quality-list.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: 'DD-MMM-YYYY'
  });
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DocFormsTypesComponent,
    PicsUrlComponent,
    AddDocFormsComponent,
    ConfirmDialogComponent,
    LogoutComponent,
    DemandMasterComponent,
    DocumentCodeMasterComponent,
    FormatCodeMasterComponent,
    AddDocumentCodeComponent,
    AddFormatCodeComponent,
    TranslationParameterListComponent,
    RecipientsListComponent,
    AddTranslationParameterComponent,
    AddRecipientsComponent,
    TranslationStatusComponent,
    
    DataQualityListComponent,
    
  ],
  imports: [
    SharedModule,
    AuthorizationModule.forRoot(),
    RnltErrorsModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgHttpLoaderModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    ],
  providers: [
    { provide: ValidationHandler, useClass: JwksValidationHandler },
    { provide: OAuthModuleConfig, useValue: oAuthModuleConfig },
    NgbActiveModal,
    ConfirmDialogService,
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddDocFormsComponent,
    ConfirmDialogComponent,
    AddDocumentCodeComponent,
    AddFormatCodeComponent,
    AddTranslationParameterComponent,
    AddRecipientsComponent,
    TranslationStatusComponent
  ]
})

export class AppModule { }