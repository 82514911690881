import {AuthConfig} from 'angular-oauth2-oidc-codeflow';
import {environment} from '../../../../environments/environment';

export const authConfig: AuthConfig = {
     // Urls of EndPoints
     issuer: environment.issuer,
     loginUrl: environment.loginUrl,
     userinfoEndpoint: environment.userinfoEndpoint,
     clientId: environment.clientId,
     // dummyClientSecret: environment.clientSecret,
     scope: environment.scope,
    /* Common conf */
    redirectUri: window.location.origin + '/auth/oidc',
    // silentRefresh
    silentRefreshRedirectUri:  window.location.origin + '/silent-refresh.html',
    silentRefreshShowIFrame: true,
    silentRefreshIFrameName: 'rnlt-angular-oauth-oidc-silent-refresh-iframe',
    showDebugInformation: false,
    sessionChecksEnabled: false,
    disableNonceCheck: true,
    disableAtHashCheck: true
};