import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/shared/common/common';

@Component({
  selector: 'app-demand-master',
  templateUrl: './demand-master.component.html',
  styleUrls: ['./demand-master.component.css']
})
export class DemandMasterComponent extends Common implements OnInit {
  demandList: any;

  constructor(protected toastr: ToastrService, private formBuilder: FormBuilder, protected httpclient: HttpClient) {
    super(toastr, httpclient);
  }

  ngOnInit() {
    this.loadList();
  }

  updateDemandMaster() {
    this.updatedemandMasterList(this.demandList).subscribe(res => {
        this.ShowNotification('Demand Request Updated', 'S');
        this.demandList = res;
    },
    (err) => {
      this.ShowNotification(err, 'E');
    });
  }

  changeDemandStatus(event, newItem) {
    const flag = event.srcElement.checked;
    this.demandList.forEach( (element, index) => {
      if (element.demandReqId === newItem) {
        this.demandList[index].demandRequested = (flag === true ? 1 : 0) ;
      }
    });
  }

  findIndexToUpdate(newItem) {
        return newItem.id === this;
  }

  loadList() {
    this.demandList = [];
    this.getdemandMasterList().subscribe( response => { this.demandList = response; } );
  }

}
