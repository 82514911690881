import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/shared/common/common';

@Component({
  selector: 'app-add-translation-parameter',
  templateUrl: './add-translation-parameter.component.html',
  styleUrls: ['./add-translation-parameter.component.css']
})
export class AddTranslationParameterComponent extends Common implements OnInit {
  addTrasParameter: FormGroup;
  isSubmitted  =  false;
  SegCodeList: any;
  LangList: any;
  segCode = '';
  langCode: any = [];
  tempArray: any[];
  selectedLanguages: any[];
  reqJson: any = [];
  @Input() title: string;
  @Input() SelectedItem: any;
   // tslint:disable-next-line:max-line-length
  constructor(private formBuilder: FormBuilder, protected httpClient: HttpClient, protected activeModal: NgbActiveModal, protected toastr: ToastrService) {
    super(toastr, httpClient);
  }

  ngOnInit() {
    this.getSegmentCodes();
    this.getLanguageLists();
  }

  getSegmentCodes() {
      if (this.SelectedItem.toString() === '0') {
        let tempCodeList: any;
        this.getSegmentCodeList().subscribe(res => {
          tempCodeList = res;
          if (tempCodeList.length <= 0) {
            this.ShowNotification('Segment codes not available', 'E');
          } else {
            this.SegCodeList =  res;
          }
        });
      } else {
        this.selectedLanguages = [];
        this.SegCodeList = [];
        this.segCode = this.SelectedItem.transLabel;
        this.SegCodeList.push({segId : 0, segLabel : this.SelectedItem.transLabel });
        this.SelectedItem.langLabel.split(',').forEach(ele => {
          this.selectedLanguages.push(ele.trim());
          this.langCode.push(ele.trim());
        });
      }
  }

  checkSelectedLangCode(code: string) {
    let returnType = false;
    for (const lcode of this.selectedLanguages) {
      if (lcode.toString() === code.toString()) {
        returnType = true;
        break;
      }
    }
    return returnType;
  }

  getLanguageLists() {
    this.getLanguageList().subscribe(res => { this.LangList = res;
    });
  }

  addTrasParameterData() {
    this.langCode = this.langCode.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    if (this.segCode === '') {
      this.ShowNotification('Please select segment code', 'E');
    } else if (this.langCode.length < 1) {
      this.ShowNotification('Please select language code', 'E');
    } else if (this.isSubmitted === false) {
      this.reqJson = [];
      this.langCode.forEach(element => {
        // tslint:disable-next-line: max-line-length
        this.reqJson.push({translationId : 0 , segLabel :  this.segCode, langCode :  element });
      });

      this.createSegmemtTranslationData(this.reqJson).subscribe(resp => {
        if (this.SelectedItem !== 0) {
          this.ShowNotification('Translation Parameters Updated Successfully', 'S');
        } else {
          this.ShowNotification('Translation Parameters Added Successfully', 'S');
        }
        // Code to Insert json to Database
        this.activeModal.dismiss(true);
        this.addTrasParameter.reset();
        this.isSubmitted = true;
      });
    }
  }

  setSegCode(code: string) {
    this.segCode = code;
  }

  setLangCode(lcode: string, isChecked: boolean) {
    if (isChecked) {
      this.langCode.push(lcode);
    } else {
      this.tempArray = [];
      for (const lanCode of this.langCode) {
        if (lcode.toString() !== lanCode.toString()) {
          this.tempArray.push(lanCode);
        }
      }
      this.langCode = this.tempArray;
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
