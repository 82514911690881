import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {OAuthService, UserInfo, OAuthStorage} from 'angular-oauth2-oidc-codeflow';
import {RnltErrorService} from '../error/rnlt.error.service';
import {authConfig} from './config/authConfig';
import {Authorization} from './authorization.model';
import {EnvironmentService} from '../environment/environment.service';
import {matomoConfig} from './config/matomoConfig';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';

@Injectable()
export class AuthorizationService {
  private rnltUserInfo: UserInfo;
  private doneAuthenticatingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  static displayIdentity(userProfile, source) {
    // So for only for log
    console.log(`[AuthorizationService ] : userInfo - Ipn - from ${source} ${userProfile.uid}`);
  }

  static isAllowed(authorizationIHave: Authorization, authorizationsINeed: Authorization) {
    return Authorization[authorizationsINeed] === Authorization.NONE
      || authorizationIHave === Authorization.ALL
      || authorizationIHave === authorizationsINeed;
  }

  constructor(private router: Router, private oAuthService: OAuthService, private rnltErrorService: RnltErrorService,
              private environmentService: EnvironmentService, private commonservice: CommonService) {
  }

  configureOidcAndLogin() {
    if (!this.environmentService.isOAuthEnabled()) {
      return;
    }
    this.oAuthService.configure(authConfig);
    this.oAuthService.setupAutomaticSilentRefresh();
    this.IDPLoginAndStartSession();
  }

  IDPLoginAndStartSession() {
    this.oAuthService.loadDiscoveryDocumentAndTryLogin().catch(err => {
      console.error(err);
    }).then(() => {
      if (!this.oAuthService.hasValidAccessToken() || this.rnltErrorService.isTokenExpired()) {
        // this.login();  // Implicit flow
        this.codeFlowlogin();  // Code flow
        console.log(this.oAuthService.getAccessToken());
        sessionStorage.setItem('access_token',this.oAuthService.getAccessToken());
        sessionStorage.setItem('id-token',this.oAuthService.getIdToken());
      }
      this.doneAuthenticatingSubject.next(true);
    });
  }

  getUserInfo(): UserInfo {
    if (this.rnltUserInfo) {
      return this.rnltUserInfo;
    }

    const accessToken = this.oAuthService.getAccessToken();
    if (!accessToken) {
      throw new Error('No access token');
    }

    const decodedToken = this.extractAccessTokenData(accessToken);

    this.rnltUserInfo = decodedToken as UserInfo;
    AuthorizationService.displayIdentity(this.rnltUserInfo, 'IDP');
    return this.rnltUserInfo;
  }

  private extractAccessTokenData(accessToken: string) {
    const tokenBodyFirstIndex = this.getAccessTokenBodyStartIndex(accessToken);
    const tokenBodyLastIndex = this.getAccessTokenBodyEndIndex(accessToken, tokenBodyFirstIndex);

    const accessTokenBody = this.getAccessTokenBody(accessToken, tokenBodyFirstIndex, tokenBodyLastIndex);
    const decodedToken = atob(accessTokenBody);
    return JSON.parse(decodedToken) as UserInfo;
  }

  private getAccessTokenBodyStartIndex(accessToken: string) {
    return accessToken.indexOf('.') + 1;
  }

  private getAccessTokenBodyEndIndex(accessToken: string, tokenBodyFirstIndex: number) {
    return accessToken.indexOf('.', tokenBodyFirstIndex);
  }

  private getAccessTokenBody(accessToken: string, tokenBodyFirstIndex: number, tokenBodyLastIndex: number) {
    return accessToken.substring(tokenBodyFirstIndex, tokenBodyLastIndex);
  }

  afterAuth(redirectTo, userInfo) {
    setTimeout(() => this.router.navigate([redirectTo]), 10);
    console.log('[AuthorizationService - OICD]: Piwik uid : ', userInfo.uid);
  }

  /**
   * Init Matomo/Piwik configuration
   */
  matomoCall() {
    const _PAQ = window[String('_paq')];
    const url = matomoConfig.matomoUrl;
    const matomoId = matomoConfig.matomoId;

    _PAQ.push(['trackPageView']);
    _PAQ.push(['enableLinkTracking']);
    _PAQ.push(['setTrackerUrl', url + 'piwik.php']);
    _PAQ.push(['setSiteId', matomoId]);
    // userIpn
    const userIpn = this.getUserId();
    if (!this.environmentService.isProduction()) {
      console.log('[piwik]: userIpn ', userIpn);
    }
    _PAQ.push(['setUserId', this.getUserId()]);

    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];

    g.type = 'text/javascript';
    g.async = true;
    g.defer = true;
    g.src = url + 'piwik.js';
    s.parentNode.insertBefore(g, s);
  }

  setUserInfo(rnltUserInfo: UserInfo) {
    this.rnltUserInfo = rnltUserInfo;
  }

  getUserId() {
    if (this.rnltUserInfo) {
      return this.rnltUserInfo.uid;
    } else {
      return null;
    }
  }

  getUserName() {
    if (this.rnltUserInfo) {
      console.log(this.rnltUserInfo);
      return this.rnltUserInfo.firstname + ' ' + this.rnltUserInfo.lastname;
    } else {
      return null;
    }
  }

  login() {
    // initiate open ID connect
    this.oAuthService.initImplicitFlow();
  }

  codeFlowlogin() {
    // initiate open ID connect
    this.oAuthService.initAuthorizationCodeFlow();
  }

  logout() {
    this.oAuthService.logOut(false);
    const now: Date = new Date();
    sessionStorage.setItem('session_terminated_at', now.toLocaleDateString() + ' ' + now.toTimeString());
  }

  public whenDoneAuthenticating(): Observable<boolean> {
    return this.doneAuthenticatingSubject.asObservable().pipe(
      filter((isDone: boolean) => isDone),
      first()
    );
  }
}