import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Common } from 'src/app/shared/common/common';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AddFormatCodeComponent } from 'src/app/model/add-format-code/add-format-code.component';

@Component({
  selector: 'app-format-code-master',
  templateUrl: './format-code-master.component.html',
  styleUrls: ['./format-code-master.component.css']
})
export class FormatCodeMasterComponent extends Common implements OnInit, OnDestroy {
  formatCodeList: any;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  selectedRow: number;
 // tslint:disable-next-line:max-line-length
  constructor(private confirmDialogService: ConfirmDialogService, protected httpclient: HttpClient, protected toastr: ToastrService, protected modalService: NgbModal) {
    super(toastr, httpclient);
  }

  ngOnInit() {
    this.getFormatCodeList();
  }

  open() {
    const title = 'Add Format Code';
    this.selectedRow = 0;
    this.confirmDialogService.openModal(AddFormatCodeComponent, title, this.selectedRow)
    .then((confirmed) => {
      this.rerender(); this.getFormatCodeList();
    })
    .catch(() => {  this.rerender(); this.getFormatCodeList(); });
  }

  getFormatCodeList() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getAllFormatTypes().subscribe(res => {
      this.formatCodeList =  res;
      this.dtTrigger.next();
      if (this.formatCodeList.length <= 0) {
        this.ShowNotification('Format type list not available', 'E');
      }
    });
  }


  rerender(): void {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
