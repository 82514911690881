import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
