import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/shared/common/common';

@Component({
  selector: 'app-add-recipients',
  templateUrl: './add-recipients.component.html',
  styleUrls: ['./add-recipients.component.css']
})
export class AddRecipientsComponent extends Common implements OnInit {

  recpForms: FormGroup;
  recList: any;
  valid: boolean;
  @Input() SelectedItem: any;
  // tslint:disable-next-line:max-line-length
  constructor(private formBuilder: FormBuilder, protected httpClient: HttpClient, protected activeModal: NgbActiveModal, protected toastr: ToastrService) {
    super(toastr, httpClient);
  }

  ngOnInit() {
    this.recpForms  =  this.formBuilder.group({
      crRecId: [this.SelectedItem === 0 ? this.SelectedItem : this.SelectedItem.crRecId],
      // tslint:disable-next-line: max-line-length
      emailId: new FormControl(this.SelectedItem === 0 ? '' : this.SelectedItem.emailId, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
      interfaceCode: [sessionStorage.getItem('interface_code')]
    });
  }

  addRecipient() {
    if (this.recpForms.status === 'VALID') {
      this.getRecipientsListFromService().subscribe(res => {
          this.recList = res;
          this.valid = true;
          this.recList.forEach(element => {
            if (element.emailId === this.recpForms.value.emailId && this.recpForms.value.crRecId !== element.crRecId) {
              this.valid = false;
            }
          });
          if (this.valid === true) {
            this.addRecipientEmail(this.recpForms.value).subscribe( result => {
              if (this.recpForms.value.crRecId === 0) {
                this.ShowNotification('Recipient email Added Successfully', 'S');
              } else {
                this.ShowNotification('Recipient email Updated Successfully', 'S');
              }
              this.activeModal.dismiss();
            }, (err) => {
              this.ShowNotification(err, 'E');
            });
          } else {
            this.ShowNotification('Email Id already exists', 'E');
          }
      });
    } else if (this.recpForms.value.emailId !== '') {
        this.ShowNotification('Please enter valid email id', 'E');
    } else {
        this.ShowNotification('Please fill required fields', 'E');
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
