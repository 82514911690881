import {NgModule} from '@angular/core';
import {TemplateComponent} from './template.component';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../layout/header/header.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { LeftmenuComponent } from '../layout/leftmenu/leftmenu.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [ RouterModule, CommonModule, BrowserModule],
  declarations: [
    TemplateComponent,
    HeaderComponent,
    FooterComponent,
    LeftmenuComponent
  ],
  exports: [
    TemplateComponent,
    HeaderComponent,
    FooterComponent,
    LeftmenuComponent]
})
export class TemplateModule {
  userId: string;
}
