import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.css']
})
export class LeftmenuComponent implements OnInit {
  menuItems: any[];
  showSideMenu = true;
  private listTitles: any[];
    location: Location;
    private toggleButton: any;
    private sidebarVisible: boolean;

    constructor() {
    }

    ngOnInit() {
      this.menuItems = [
        { path: '/datahub', title: 'ETL DataHub', code: 'ASD', icon: 'fa-cog', class: '',
            children : [
              { path: '/dashboard/documentTypes', title: 'Document Formats and Types',  icon: '', class: ''},
            ]
        },
 //       { path: '/datahub', title: 'ETL RPS', code: 'RPS', icon: 'fa-cog', class: '',
 //         children : [
 //         ]
 //       },
 //       { path: '/datahub', title: 'ETL  CAX', code: 'RSITE', icon: 'fa-cog', class: '',
 //           children : [
 //           ]
 //       },
        { path: '/datahub', title: 'ETL PENT', code: 'PENT', icon: 'fa-cog', class: '',
            children : [
              { path: '/dashboard/translationParameters', title: 'Translation Parameters',  icon: '', class: ''},
              { path: '/dashboard/recipients', title: 'CR Recipients',  icon: '', class: ''},
              { path: '/dashboard/dataQualityCheck', title: 'Data Quality Check',  icon: '', class: ''}
            ]
        },
        { path: '/datahub', title: 'Administrator', code: 'ASD', icon: 'fa-cog', class: '',
            children : [
              { path: '/master/demand', title: 'Demand Master',  icon: '', class: ''},
              { path: '/master/documentCode', title: 'Document Codes',  icon: '', class: ''},
              { path: '/master/formatCode', title: 'Format Types',  icon: '', class: ''},
            ]
        }
      ];
    }
    showMenu(i) {
      console.log(i);
    }

    setInterfaceCode(code: string) {
        sessionStorage.setItem('interface_code', code);
    }

}