import { Component, OnInit } from '@angular/core';
import {AuthorizationService} from '../../authorization/authorization.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userName: string;
  profieShow = false;
  selectedLang: string;
  constructor(private authorizationService: AuthorizationService, private translate: TranslateService, private route: Router) {
    if (sessionStorage.language === undefined) {
      sessionStorage.language = 'en';
    }
    translate.setDefaultLang(sessionStorage.language);
    this.translate.use(sessionStorage.language);
   }

  async ngOnInit() {
    this.userName = this.authorizationService.getUserName();
    this.selectedLang = sessionStorage.language;
  }

  switchLanguage(language: string) {
    sessionStorage.language = language;
    this.translate.use(language);
    this.selectedLang = language;
  }
  
  
  logout() {
    this.authorizationService.logout();
    this.route.navigate(['/logout']);
  }

}
