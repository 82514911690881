// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const baseUrl = 'https:' + window.location.origin.substring(window.location.protocol.length);
let issuer           = 'https://idp2.renault.com/nidp/oauth/nam';
let loginUrl         = 'https://idp2.renault.com/nidp/oauth/nam/authz';
let userinfoEndpoint = 'https://idp2.renault.com/nidp/oauth/nam/userinfo';
//let clientId         = '82bdb28f-1eda-4b6a-a1a4-4cede7a7b6c1'; //Use this one for local
let clientId         = '6037060f-827c-42bd-bb19-9ca78b4717de';
let production       = false;

if (baseUrl === 'https://ope.pit.ope.aws.renault.com') {

  issuer            = 'https://idp.renault.com/nidp/oauth/nam';
  loginUrl         = 'https://idp.renault.com/nidp/oauth/nam/authz';
  userinfoEndpoint = 'https://idp.renault.com/nidp/oauth/nam/userinfo';
  clientId          = '090a8f63-fbc5-4a4a-b209-1c7232951d8e';
  production        = true;
} else if (baseUrl === 'https://re7.pit.re7.aws.renault.com') {
  clientId          = 'fedacfd9-62d2-41e7-996e-2398398896a7';
  production        = false;
} else if (baseUrl === 'https://tst.pit.tst.aws.renault.com') {
  clientId          = 'dddefedc-4e66-4660-bdb1-d432a77f22ad';
  production        = false;
}

export const environment = {

  production,
  issuer,
  loginUrl,
  userinfoEndpoint,
  clientId,
  // IDP conf
  oAuthEnable: true,
  scope: 'openid arca',
  // tslint:disable-next-line: max-line-length
  allowedUrls: [ 'https://apis.renault.com'],
  // Matomo/Piwik settings
  matomoUrl: 'https://piwik.renault-digital.com/',
  matomoId: 1541,

  // Application URL Config

  BaseUrl : '/api/',

  docTypeList: 'DocFormats/getDocFormatDetails?interfaceCode=',
  picUrlList: 'assetManagement/assetTypeFormat?interfaceCode=',
  docCodeList: 'DocFormats/getDocumentTypes',
  formCodeList: 'DocFormats/getFormatTypes',
  postPicUrlForm : 'assetManagement/saveAssetList',
  deleteDocFormList: 'DocFormats/deleteDocFormatById?id=',
  postDocFormatForm : 'DocFormats/createDocFormatData',
  updateDocFormatForm : 'DocFormats/updateDocFormatById',
  getDocFormatDetailsById : 'DocFormats/getDocFormatDetailsById?docformatId=',
  getClientId : 'DocFormats/getClientDetails',
  getDemandList: 'demandRequest/getDemandReqDetails',
  saveDemandList: 'demandRequest/saveDemandReqDetails',
  getAllDocumentTypes: 'docTypes/getAllDocumentTypes',
  saveDocumentMaster: 'docTypes/saveDocumentType',
  getAllFormatTypes: 'formatTypes/getAllFormatTypes',
  saveFormatMaster: 'formatTypes/saveFormatType',
  transParamList: 'SegTranslation/getSegTranslationList',
  dataQualitycheck: 'DataQuality/getDataQualityDetails',
  deleteTransParamList: 'SegTranslation/deleteSegTranslation?segLabel=',
  saveSegTransData: 'SegTranslation/createSegTranslation',
  getSegementList: 'SegTranslation/getAddSegmentTypes',
  getLanguageList: 'language/getAllLanguageList',
  getRecipientList: 'crRecipients/getAllCRRecipients',
  saveRecipientList: 'crRecipients/saveCRRecipients',
  deleteRecipientList: 'crRecipients/deleteCrRecipient?id=',
  savePitToPentRequest: 'SegTranslation/savePitToPentRequest',
  getSegTranslationStatus: 'SegTranslation/getSegTranslationStatus?segLabel=',
  cancelPitToPentRequest: 'SegTranslation/cancelPitToPentRequest',
  exportDemandRequest: 'SegTranslation/getExportDemandData'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
