import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddRecipientsComponent } from '../../model/add-recipients/add-recipients.component';
import { CommonService } from 'src/app/services/common.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/shared/common/common';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-recipients-list',
  templateUrl: './recipients-list.component.html',
  styleUrls: ['./recipients-list.component.css']
})
export class RecipientsListComponent extends Common implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  recpList: any;
  selectedRow: any;
  dtTrigger: Subject<any> = new Subject();

   // tslint:disable-next-line:max-line-length
   constructor(private confirmDialogService: ConfirmDialogService, protected httpclient: HttpClient, protected toastr: ToastrService, protected modalService: NgbModal) {
    super(toastr, httpclient);
  }

  ngOnInit() {
    this.selectedRow  = 0;
    this.getRecipientsList();
  }

  getRecipientsList(): void {

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getRecipientsListFromService().subscribe(resp => {
      this.selectedRow  = 0;
      this.recpList = resp;
      this.dtTrigger.next();
    },
    error => {
      this.ShowNotification('Error while loading list', 'E');
    });
  }

  selectedItem(id: any) {
    this.selectedRow = id;
  }

  public open() {
    const title = 'Add Recipients List';
    this.selectedRow = 0;
    this.confirmDialogService.openModal(AddRecipientsComponent, title, this.selectedRow)
    .then((confirmed) => {
      this.rerender(); this.getRecipientsList();
    })
    .catch(() => {  this.rerender(); this.getRecipientsList(); });
  }

  editItem() {
    if (this.selectedRow === 0) {
        this.ShowNotification('Please select any one', 'E');
    } else {
      const title = 'Edit Recipients List';
      this.confirmDialogService.openModal(AddRecipientsComponent, title, this.selectedRow)
        .then((confirmed) => {
          this.rerender();
          this.getRecipientsList();
        })
        .catch(() => {  this.rerender(); this.getRecipientsList(); } );
    }
  }

  deleteItem() {
    if (this.selectedRow !== 0) {
      let respStr: any;
      this.confirmDialogService.confirm('Confirm', 'Do you really want to delete?')
      .then((confirmed) => { if (confirmed === true) {
          this.deleteRecpListById(this.selectedRow.crRecId).subscribe(resp => {
            respStr = resp;
            if (respStr === true) {
              this.ShowNotification('Selected Row Deleted Successfully', 'S');
              this.rerender();
              this.getRecipientsList();
            } else {
              this.ShowNotification(resp.toString(), 'E');
            }
        });
      }})
      .catch(() => console.log('User dismissed'));
    } else {
      this.ShowNotification('Please select any one', 'E');
    }
  }

  rerender(): void {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
         dtInstance.destroy();
     });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
