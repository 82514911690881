import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

export class Common extends CommonService {

    constructor(protected toastr: ToastrService, protected httpClient: HttpClient) {
        super(httpClient);
    }

  ShowNotification(message: string, type: string) {
    if (type === 'S') {
      this.toastr.success(message);
    } else {
      this.toastr.error(message);
    }
  }

}
