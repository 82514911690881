import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {OpenIdCallBackComponent} from './shared/authorization/callback/open-id-call-back.component';
import {NgModule} from '@angular/core';
import {RnltErrorsComponent} from './shared/error/rnlt-errors.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthGuard} from './shared/authorization/authguard/authguard.service';
import {Authorization} from './shared/authorization/authorization.model';
import {TemplateComponent} from './shared/template/template.component';
import { DocFormsTypesComponent } from './components/doc-forms-types/doc-forms-types.component';
import { PicsUrlComponent } from './components/pics-url/pics-url.component';
import { LogoutComponent } from './components/logout/logout.component';
import { DemandMasterComponent } from './components/master/demand-master/demand-master.component';
import {DocumentCodeMasterComponent} from './components/master/document-code-master/document-code-master.component';
import { FormatCodeMasterComponent } from './components/master/format-code-master/format-code-master.component';
import { TranslationParameterListComponent } from './components/translation-parameter-list/translation-parameter-list.component';
import { RecipientsListComponent } from './components/recipients-list/recipients-list.component';
import { DataQualityListComponent } from './components/data-quality-list/data-quality-list.component';
const routes: Routes = [
  {path: '', redirectTo: 'auth/oidc', pathMatch: 'full'},
  {path: 'auth', children: [
    {path: 'oidc', component: OpenIdCallBackComponent}
  ]},
  {path: 'logout', component: LogoutComponent},
  {path: 'dashboard', component: TemplateComponent, children: [
      {path: '', component: DashboardComponent, canActivate: [AuthGuard], data: {authorizations: Authorization.NONE}},
      {path: 'documentTypes', component: DocFormsTypesComponent, canActivate: [AuthGuard], data: {authorizations: Authorization.NONE}},
      {path: 'picsUrl', component: PicsUrlComponent, canActivate: [AuthGuard], data: {authorizations: Authorization.NONE}},
      {path: 'translationParameters', component: TranslationParameterListComponent, canActivate: [AuthGuard],
      data: {authorizations: Authorization.NONE}},
      {path: 'recipients', component: RecipientsListComponent, canActivate: [AuthGuard], data: {authorizations: Authorization.NONE}},
   
      {path: 'dataQualityCheck', component: DataQualityListComponent, canActivate: [AuthGuard],
      data: {authorizations: Authorization.NONE}} ]
  },
  {path: 'master', component: TemplateComponent, children: [
    {path: '', component: DashboardComponent, canActivate: [AuthGuard], data: {authorizations: Authorization.NONE}},
    {path: 'demand', component: DemandMasterComponent, canActivate: [AuthGuard], data: {authorizations: Authorization.NONE}},
    {path: 'documentCode', component: DocumentCodeMasterComponent, canActivate: [AuthGuard], data: {authorizations: Authorization.NONE}},
    {path: 'formatCode', component: FormatCodeMasterComponent, canActivate: [AuthGuard], data: {authorizations: Authorization.NONE}}
    ]
  },
  {path: 'error/:errorType', component: RnltErrorsComponent},
  {path: 'error', component: RnltErrorsComponent},
  {path: '**', redirectTo: 'error'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}