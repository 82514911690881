import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/shared/common/common';

@Component({
  selector: 'app-pics-url',
  templateUrl: './pics-url.component.html',
  styleUrls: ['./pics-url.component.css']
})
export class PicsUrlComponent extends Common implements OnInit {

  picUrl: FormGroup;
  picList: any;
  isSubmitted  =  false;
  reqJson: {};
  constructor(protected toastr: ToastrService, private formBuilder: FormBuilder, protected httpclient: HttpClient) {
    super(toastr, httpclient);
  }

  ngOnInit() {
    this.picUrl = this.formBuilder.group({
      picType: ['', Validators.required]
    });
    this.loadList();
  }

  loadList() {
    this.picList = [];
    this.getPicUrlList().subscribe( response => { this.picList = response; this.updateField(); } );
    this.isSubmitted = false;
  }

  updateField(): void {
    for ( const values of Object.entries(this.picList)) {
       if (values[0] !== 'interfaceCode' &&  values[1] === '1') {
        const picType = 'picType';
        this.picUrl.setValue( {picType : values[0]});
       }
    }
  }

  addPicUrl() {
    this.reqJson = {};
    if (this.picUrl.value.picType !== '' && this.isSubmitted === false) {
      for ( const keys of Object.keys(this.picList)) {
        if (this.picUrl.value.picType === keys) {
          this.reqJson[keys] = 1;
        } else if (keys === 'interfaceCode') {
          this.reqJson[keys] = sessionStorage.getItem('interface_code');
        } else {
          this.reqJson[keys] = 0;
        }
      }
      this.updatePicUrlList(this.reqJson).subscribe(response => {
        this.isSubmitted = true;
        this.loadList();
        this.ShowNotification('Selected Option Updated Successfully', 'S');
      });
    } else {
      this.ShowNotification('Please select any one', 'E');
    }
  }

}
