import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/shared/common/common';

@Component({
  selector: 'app-add-doc-forms',
  templateUrl: './add-doc-forms.component.html',
  styleUrls: ['./add-doc-forms.component.css']
})
export class AddDocFormsComponent extends Common implements OnInit {
  docForms: FormGroup;
  isSubmitted  =  false;
  docCodeList: any;
  formCodeList: any;
  docformat = '';
  pimLabel = '';
  formatCode: any = [];
  tempArray: any[];
  selectedFormats: any[];
  reqJson: object = {};
  @Input() title: string;
  @Input() SelectedItem: any;
   // tslint:disable-next-line:max-line-length
  constructor(private formBuilder: FormBuilder, protected httpClient: HttpClient, protected activeModal: NgbActiveModal, protected toastr: ToastrService) {
    super(toastr, httpClient);
  }

  ngOnInit() {
    this.docForms  =  this.formBuilder.group({
      doc_code: ['', Validators.required],
      doc_label: ['', Validators.required],
      formate_code: ['', Validators.required]
    });
    this.getDocumentCodes();
    this.getFormatCodes();
  }

  getDocumentCodes() {
    if (this.SelectedItem.toString() === '0'.toString()) {
      this.getDocumentCodeList().subscribe(res => {
        this.docCodeList =  res;
        if (this.docCodeList.length <= 0) {
          this.ShowNotification('Document codes not available', 'E');
        }
      });
      this.selectedFormats = [];
    } else {
      this.getDocumentCodeListById(this.SelectedItem).subscribe(res => {
        this.docCodeList = res;
        this.selectedFormats = this.docCodeList[0].formatCode.replace('"', '').split(',');
        for (const item of this.selectedFormats) {
          this.setFormatCode(item, true);
        }
        this.setDocCode(this.docCodeList[0].docCode, this.docCodeList[0].pimLabel);
      });
    }
  }

  checkSelectedFormatCode(code: string) {
    let returnType = false;
    for (const fcode of this.selectedFormats) {
      if (fcode.toString() === code.toString()) {
        returnType = true;
        break;
      }
    }
    return returnType;
  }

  getFormatCodes() {
    this.getFormCodeList().subscribe(res => { this.formCodeList = res;
    });
  }

  addDocFormTypes() {
    this.formatCode = this.formatCode.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    if (this.docformat === '') {
      this.ShowNotification('Please select document code', 'E');
    } else if (this.formatCode.length < 1) {
      this.ShowNotification('Please select format code', 'E');
    } else if (this.isSubmitted === false) {
      let code = 'docFormatId';
      this.reqJson[code] = this.SelectedItem;
      code = 'docCode';
      this.reqJson[code]     = this.docformat;
      code = 'pimLabel';
      this.reqJson[code]     = this.pimLabel;
      code = 'formatCode';
      this.reqJson[code]  = this.formatCode.join(',');
      code = 'interfaceCode';
      this.reqJson[code] = sessionStorage.getItem('interface_code');
      this.addDocFormList(this.reqJson).subscribe(resp => {
        if (this.SelectedItem !== 0) {
          this.ShowNotification('Document and Format Type Updated', 'S');
        } else {
          this.ShowNotification('Document and Format Type added', 'S');
        }
        // Code to Insert json to Database
        this.activeModal.dismiss(true);
        this.docForms.reset();
        this.isSubmitted = true;
      });
    }
  }

  setDocCode(code: string, pimLabel: string) {
    this.docformat = code;
    this.pimLabel = pimLabel;
  }

  setFormatCode(fcode: string, isChecked: boolean) {
    if (isChecked) {
      this.formatCode.push(fcode);
    } else {
      this.tempArray = [];
      for (const fmcode of this.formatCode) {
        if (fmcode.toString() !== fcode.toString()) {
          this.tempArray.push(fmcode);
        }
      }
      this.formatCode = this.tempArray;
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
