import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  public isOAuthEnabled(): boolean {
    return environment.oAuthEnable;
  }

  public isProduction(): boolean {
    return environment.production;
  }
}
