import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {OAuthService, UserInfo} from 'angular-oauth2-oidc-codeflow';
import {RnltErrorService} from '../../error/rnlt.error.service';
import {FORBIDDEN} from '../../error/rnlt.error.model';
import {AuthorizationService} from '../authorization.service';
import {EnvironmentService} from '../../environment/environment.service';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-open-id-callback',
    templateUrl: 'open-id-call-back.component.html',
    styleUrls: ['open-id-call-back.component.css']
  }
)
export class OpenIdCallBackComponent implements OnInit, OnDestroy {
  private redirectUrl = '/dashboard';
  userInfo: UserInfo;
  doneAuthenticatingSubscription: Subscription;

  constructor(private oAuthService: OAuthService,
              private rnltErrorService: RnltErrorService,
              private authorizationService: AuthorizationService,
              private environmentService: EnvironmentService) {
  }

  ngOnInit() {
    // 1- if insecure mode
    if (!this.environmentService.isOAuthEnabled()) {
      console.log('[oAuth security disabled !]');
      return;
    }

    // We should wait for the Authentication process to terminate
    this.doneAuthenticatingSubscription = this.authorizationService.whenDoneAuthenticating()
      .subscribe(() => {

        // 2- if we have an access token but it is not valid => redirect to FORBIDDEN
        if (this.oAuthService.getAccessToken() && !this.oAuthService.hasValidAccessToken()) {
          console.log('[OpenIdCallBackComponent - OIDC]: ', 'Access token is invalid');
          this.rnltErrorService.redirect(FORBIDDEN);
          return;
        } else {
          console.log('[OpenIdCallBackComponent - OIDC]: ', 'Access token is valid');
        }

        // 3- If we have a valid access token ==> get user identity
        if (this.oAuthService.getAccessToken() && this.oAuthService.hasValidAccessToken()) {
          try {
            this.userInfo = this.authorizationService.getUserInfo();
          } catch (e) {
            this.userInfo = null;
            console.error('Can get user info');
          }
        }

        // 4- Manage after IDP login
        if (this.userInfo) {
          console.log('Redirecting to ' + this.redirectUrl);
          this.authorizationService.afterAuth(this.redirectUrl, this.userInfo);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.doneAuthenticatingSubscription) {
      this.doneAuthenticatingSubscription.unsubscribe();
    }
  }
}