import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Common } from 'src/app/shared/common/common';


@Component({
  selector: 'app-data-quality-list',
  templateUrl: './data-quality-list.component.html',
  styleUrls: ['./data-quality-list.component.css']
})
export class DataQualityListComponent extends Common implements OnInit {

  dataQualityList: any = [];
  fromMinDate = new Date();
  toMinDate = new Date();
  startDateString: any;
  valuesChoices: any[] =[
    10, 25, 50, 100
  ]
  entriesLength = 10;
  searchEntries: string
  printOrder: string[];
  segment: string;
  // tslint:disable-next-line:max-line-length
  constructor(private confirmDialogService: ConfirmDialogService, protected httpclient: HttpClient, protected toastr: ToastrService, protected modalService: NgbModal) {
    super(toastr, httpclient);
  }

  ngOnInit() {
    this.printOrder = ['pa_name_fr_MASTER','modification_date_pa_name_fr_MASTER', 'pa_description_fr_MASTER', 'modification_date_pa_description_fr_MASTER', 'pa_technical_description_fr_MASTER', 'modification_date_pa_technical_description_fr_MASTER'];
    this.dataQualityList = []   
    this.searchEntries = ''; 
    this.segment = '';
  }

  searchResults(): void {
    this.dataQualityList = [];
    this.segment = '';
    this.getDataQualitycheck(this.searchEntries).subscribe((resp: any[]) => {
      if(resp.length > 0){
        resp.forEach((elements) => {
          this.dataQualityList[elements.pimKey] = elements.pimValue != undefined && elements.pimValue != '' ? elements.pimValue.replaceAll('"', '') : '-';
          if(elements.pimKey == 'categories') {
            this.segment = elements.pimValue.substring(3, 4);
          }
        });
      }
    },
    error => {
      this.dataQualityList = [];
      this.ShowNotification('Error while loading list', 'E');
    });
  }

  getStatus(value){
      if(value != undefined && new Date(value).toString() == 'Invalid Date' && value.toString().length > 1)
        return true;
      else 
        return false;
  }

}
