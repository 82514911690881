import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NOT_FOUND, RnltErrorMessage, rnltErrorMessages} from './rnlt.error.model';

@Component({
    selector: 'app-rnlt-errors',
    templateUrl: 'rnlt-errors.component.html',
    styleUrls: ['rnlt-errors.component.scss']
})
export class RnltErrorsComponent implements OnInit {
    rnltErrorMessage: RnltErrorMessage;
    errorNumber: number;

    constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.errorNumber = +params[`errorType`];
            this.rnltErrorMessage =  rnltErrorMessages[this.errorNumber];
            if (isNaN(this.errorNumber) || !this.errorNumber || !this.rnltErrorMessage) {
                this.errorNumber = NOT_FOUND;
                this.rnltErrorMessage = rnltErrorMessages[NOT_FOUND];
            }
        });
    }

    redirectAfterError() {
        this.router.navigateByUrl(this.rnltErrorMessage.buttonLink).then(() => window.location.reload());
    }
}
